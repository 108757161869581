<template>
  <div class="home container user-select-none">
    <span class="titoli font-effect-shadow-multiple">
      <div>Il figlio di Liza .com</div>
      <div id="titleVal" class="transition-in-2s">... e di Valerio !</div>
    </span>

    <!-- <h2>isLogged: {{ isLogged }}</h2> -->
    <!-- <p>{{$i18n.locale}}</p> -->

    <div id="cf" class="mb-3">
      <img src="../assets/images/family2.png" class="liz" />
      <img
        id="valImg"
        src="../assets/images/family1.png"
        class="val transition-in-8s"
      />
    </div>

    <div
      id="skipIntro"
      v-if="introPlayed"
      @click="skipIntro"
      class="mt-0 transition-in-1s"
    >
      <div type="button" class="btn btn-skip w-100">Skip Intro...</div>
    </div>

    <div id="loginSection" class="transition-in-2s mt-2 mb-5">
      <!-- bandiere -->
      <div class="row align-middle">
        <div class="col">
          <div
            class="btn btn-flag w-100 align-middle"
            @click.prevent="changeLocale('it')"
          >
            <img
              v-bind:src="require('../assets/images/it.jpg')"
              height="40px"
            />
          </div>
        </div>
        <div class="col">
          <div class="btn btn-flag w-100" @click.prevent="changeLocale('sq')">
            <img
              v-bind:src="require('../assets/images/al.jpg')"
              height="40px"
            />
          </div>
        </div>
        <div class="col">
          <div class="btn btn-flag w-100" @click.prevent="changeLocale('en')">
            <img src="../assets/images/uk.jpg" height="40px" />
          </div>
        </div>
      </div>

      <!-- dati login -->
      <div v-if="!isLogged" class="mt-4">
        <form class="mx-auto">
          <div class="">
            <label for="name" class="form-label">{{ $t("your_name") }}</label>
            <input
              type="text"
              class="form-control"
              v-model="name"
              name="name"
            />
            <!-- <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
          </div>
          <div class="mt-3">
            <label for="pwd" class="form-label" v-bind:question-id="id">{{
              rdmquestion
            }}</label>
            <input
              type="text"
              class="form-control"
              v-model="password"
              name="pwd"
            />
          </div>

          <div>
            <button
              id="btnLogin"
              class="btn btn-success w-100 mt-4"
              @click.prevent="login"
            >
              Login
            </button>
          </div>

          <div class="mt-1" v-if="wrong_answers > 2">
            <button
              class="btn btn-sm btn-danger w-100 mt-2 mb-4"
              @click.prevent="getEasyQuestion"
            >
              {{ $t("help1") }}
            </button>
          </div>
          <div v-else>
            <!-- altra domanda -->
            <button
              class="btn btn-sm btn-warning w-100 mt-2 mb-4"
              @click.prevent="getAnotherQuestion"
            >
              {{ $t("another_question") }}
            </button>
          </div>
        </form>
      </div>
      <div v-else class="mt-4">
        <!-- sono loggato -->
        <hr />
        <p>{{ $t("welcome") }} {{ this.name }}!</p>

        <hr />

        <div id="choiceSection">
          <!-- info neonato -->
          <div
            class="btn btn-sm btn-success w-100"
            @click.prevent="goToPage('Info')"
          >
            {{ $t("info") }}
          </div>

          <!-- lasciare messaggio -->
          <div
            class="btn btn-sm btn-warning w-100"
            @click.prevent="goToPage('Message')"
          >
            {{ $t("msg") }}
          </div>

          <!-- voglio fare regalo -->
          <div
            v-if="giftEnabled"
            class="btn btn-sm btn-gift w-100"
            @click.prevent="goToPage('List')"
          >
            {{ $t("gift") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { messages } from "../../server/translations";
// import moment from "moment";

export default {
  name: "home",
  data() {
    return {
      id: 0,
      name: "",
      rdmquestion: "",
      password: "",
      question: "",
      wrong_answers: 0,
      introPlayed: false,
    };
  },
  computed: {
    ...mapGetters(["isLogged", "giftEnabled"]),
  },
  methods: {
    login() {
      if (this.name == "") {
        this.showMissingWarning();
        return false;
      }
      if (this.password == "") {
        this.showMissingWarning();
        return false;
      }

      this.$store
        .dispatch("login", {
          id: this.id,
          password: this.password,
          name: this.name,
          language: this.$i18n.locale,
        })
        .catch((err) => {
          this.wrong_answers += 1;

          this.showLoginError(err);
        })
        .then(() => {
          this.password = "";
          console.info(`Welcome ${this.name}!`);

          //salva nome
          localStorage.setItem("name", this.name);

          this.loadData();
        });
    },
    changeLocale(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem("locale", lang);
    },
    showMissingWarning() {
      let locale = this.$i18n.locale;
      var msg = "";
      if (this.name == "") {
        msg = messages[locale].missing_name;
      } else if (this.password == "") {
        msg = messages[locale].missing_answer;
      }

      this.$notify({
        group: "notif",
        type: "warn",
        duration: 3500,
        title: "<div class='cayman'>🐊</div>",
        text: `<div>${msg}</div>`,
      });
    },
    showLoginError(err) {
      let locale = this.$i18n.locale;
      var msg = messages[locale].login_error;
      let errorLogin = `${err.status} - ${err.statusText}`;

      console.error(errorLogin);

      this.$notify({
        group: "notif",
        type: "error",
        duration: 2000,
        title: "<div class='cayman'>🐊</div>",
        text: `<div>${msg}</div>`,
      });
    },
    skipIntro() {
      //document.getElementById("skipIntro").classList.remove("transition-in-1s");
      document.getElementById("skipIntro").style.opacity = 0;

      document.getElementById("valImg").classList.remove("transition-in-8s");
      document.getElementById("titleVal").classList.remove("transition-in-2s");
      document
        .getElementById("loginSection")
        .classList.remove("transition-in-2s");

      document.getElementById("titleVal").style.opacity = 1;
      document.getElementById("loginSection").style.opacity = 1;
      document.getElementById("valImg").style.opacity = 1;
    },
    loadData() {
      //this.$i18n.locale = this.lang
      let locale = this.$i18n.locale;

      this.axios.get(`api/answer/${locale}/${this.id}`).then((result) => {
        this.id = result.data.id;
        this.rdmquestion = result.data[`question`];
        //this.wrong_answers = 0;
      });
    },
    getEasyQuestion() {
      let locale = this.$i18n.locale;

      this.axios.get(`api/question/${locale}/easy`).then((result) => {
        this.id = result.data.id;
        this.rdmquestion = result.data[`question`];
        //this.wrong_answers = 0;
      });
    },
    getAnotherQuestion() {
      let locale = this.$i18n.locale;

      this.axios.get(`api/answer/${locale}/${this.id}`).then((result) => {
        this.id = result.data.id;
        this.rdmquestion = result.data[`question`];

        this.wrong_answers += 1;
      });
    },
    goToList() {
      this.$router.push({ name: "List" });
    },
    goToPage(name) {
      this.$router.push({ name });
    },
  },
  watch: {
    // call again the method if the route changes
    //$route: "loadData",
    "$i18n.locale": function () {
      console.log(this.$i18n.locale);
      this.loadData();
    },
  },
  beforeMount() {
    let b = localStorage.getItem("introPlayed");
    if (b === "true") {
      this.introPlayed = true;
    }

    this.name = localStorage.getItem("name");
  },
  mounted() {
    window.scrollTo(0, 0);

    this.axios.get("auth/check").then((result) => {
      if (result.data == "OK") {
        this.loadData();
      } else {
        this.loadData();
      }
    });

    //se sono loggato non devo vedere gli effetti
    if (this.isLogged) {
      //salta la presentazione
      document.getElementById("skipIntro").style.opacity = 0;
      document.getElementsByClassName("val")[0].style.opacity = 1;
      document.getElementById("titleVal").style.opacity = 1;
      document.getElementById("loginSection").style.opacity = 1;
      localStorage.setItem("introPlayed", true);
      return;
    }

    //carica img valerio
    setTimeout(() => {
      document.getElementsByClassName("val")[0].style.opacity = 1;
    }, 2000);

    //carica titolo extra e sezione login
    setTimeout(() => {
      document.getElementById("titleVal").style.opacity = 1;
      document.getElementById("loginSection").style.opacity = 1;

      //imposta intro come vista
      localStorage.setItem("introPlayed", true);
    }, 5000);

    //if (this.introPlayed) {
    setTimeout(() => {
      document.getElementById("skipIntro").style.opacity = 0;
    }, 6000);
    //}
  },
};
</script>

<style>
.home {
  background-color: #e7f2f8;
  max-width: 500px;
}

.btn-flag:hover {
  background-color: #1d3557;
}

.btn-flag {
  background-color: #e7f2f8;
  border: #1d3557 1px solid;
  box-shadow: 3px 3px 3px #ababab;
}

.btn-skip {
  background-color: #74bdcb;
  border-color: #4bb1c5;

  /* color: #1d3557; */
}

.btn-login {
  background-color: #74bdcb;
  border-color: #4bb1c5;
  color: #1d3557;
}
.btn-login:hover {
  border-color: #1d3557;
  background-color: #1d3557;
  color: white;
}

.btn-gift {
  background-color: #8fcdda;
  border-color: #87c2ce;
  color: black;
}
.btn-gift:hover {
  background-color: #7fbfcc;
  border-color: #7fbfcc;
  color: black;
}

*/ img.liz img.val {
  height: 350px;
}

#cf {
  position: relative;
  height: 281px;
  width: 330px;
  margin: 0 auto;
}

#cf img {
  left: 0;
  height: 300px;
}

#cf img.val {
  opacity: 0;
  top: 0px;
  position: absolute;
}

/* #cf img.liz {
    opacity: 1;
   
  } */

#titleVal {
  opacity: 0;
  font-size: 0.8em;
}

.transition-in-1s {
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}

.transition-in-2s {
  -webkit-transition: opacity 2s ease-in-out;
  -moz-transition: opacity 2s ease-in-out;
  -o-transition: opacity 2s ease-in-out;
  transition: opacity 2s ease-in-out;
}

.transition-in-8s {
  -webkit-transition: opacity 8s ease-in-out;
  -moz-transition: opacity 8s ease-in-out;
  -o-transition: opacity 8s ease-in-out;
  transition: opacity 8s ease-in-out;
}

#loginSection {
  opacity: 0;
}

#loginSection label {
  font-weight: bold;
  padding-left: 3px;
  margin-bottom: 3px;
}

#choiceSection div.btn {
  font-size: 1.3em;
  margin-top: 1em;
  padding: 0.3em;
  /* margin-bottom: 0.6em; */
}
</style>
