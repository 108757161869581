import Vue from "vue";
import Vuex from "vuex";
import jwtDecode from "jwt-decode";
import axios from "axios";
import _ from "lodash";
import CryptoJS from "crypto-js";
//const config = require("./../../server/config.js");
import config from "./../../server/config.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    lang: "it",
    token: null,
    name: "null",
    isLogged: false,
    //username: null,
    tokenExpireDate: null,
    cart: [],
  },

  mutations: {
    SET_LOGIN(state, payload) {
      console.log("mutation SET_LOGIN: " + token);

      let token = payload.token;
      localStorage.setItem("jwt", token);

      //salva uuid
      if (!localStorage.getItem("uuid")) {
        let uuid = uuidv4();
        localStorage.setItem("uuid", uuid);
      }

      //salva anche in state
      state.token = token;
      state.isLogged = true;

      loadPersistedCart(state);
    },
    SET_LOGOUT(state) {
      console.log("mutation SET_LOGOUT called");

      localStorage.removeItem("jwt");
      localStorage.removeItem("name");
      localStorage.removeItem("cart");

      state.isLogged = false;
      state.token = null;
    },
    ADD_TO_CART(state, item) {
      //aggiunge al carrello, se non esiste ancora
      let idx = _.findIndex(state.cart, { id: item.id });
      if (idx == -1) {
        console.log(`adding item ${item.name} to cart`);
        state.cart.push(item);

        persistCart(state.cart);
      }
    },
    REMOVE_FROM_CART(state, item) {
      let idx = _.findIndex(state.cart, { id: item.id });

      if (idx > -1) {
        console.log(`removing item ${item.name} from cart`);
        state.cart.splice(idx, 1);

        persistCart(state.cart);
      }
    },
    CLEAR_CART(state) {
      state.cart = [];
      persistCart(state.cart);
    },
    UPDATE_CART_ITEM(state, item) {
      let idx = _.findIndex(state.cart, { id: item.id });
      if (idx > -1) {
        console.log(`updating item ${item.name} in cart`);
        state.cart[idx] = item;
        persistCart(state.cart);
      }
    },
  },
  actions: {
    login: function ({ commit }, { id, password, name, language }) {
      console.log("action login called");

      return axios
        .post("auth/login", {
          user: id,
          pwd: password,
          name: name,
          language: language,
        })
        .then((result) => {
          commit("SET_LOGIN", result.data);
        });
    },
    logout: function ({ commit }) {
      commit("CLEAR_CART");
      commit("SET_LOGOUT");
    },
    addToCart: function ({ commit }, { item }) {
      commit("ADD_TO_CART", item);
    },
    removeFromCart: function ({ commit }, { item }) {
      commit("REMOVE_FROM_CART", item);
    },
    updateCartItem: function ({ commit }, { item }) {
      commit("UPDATE_CART_ITEM", item);
    },
    clearCart: function ({ commit }) {
      commit("CLEAR_CART");
    },
    verifyAuthStatus: function ({ commit }) {
      console.log("verifyAuthStatus");
      var token = localStorage.getItem("jwt");
      var hash = localStorage.getItem("hash");
      if (token) {
        //verifica che non sia expired
        if (isTokenValid(token)) {
          console.log("verifyAuthStatus, setting token : " + token);
          commit("SET_LOGIN", { token, hash });
        }
      }
    },
  },

  getters: {
    isLogged: (state) => {
      return state.isLogged;
    },
    decrypt: () => (encryptedStr) => {
      let bytes = CryptoJS.AES.decrypt(encryptedStr, "RlR80sZPGnCoClJqO8Xq");
      let decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      let data = JSON.parse(decryptedData);
      return data;
    },
    getCart: (state) => {
      return state.cart;
    },
    getCartCount: (state) => {
      return state.cart.length;
    },
    giftEnabled: () => {
      return config.giftEnabled;
    },
  },

  modules: {},
});

function isTokenValid(token) {
  var decoded = jwtDecode(token);
  var timeNow = Math.floor(new Date().getTime() / 1000.0);
  var secondsLeft = decoded.exp - timeNow;
  if (secondsLeft <= 0) {
    return false;
  }
  return true;
}

function persistCart(cart) {
  localStorage.setItem("cart", JSON.stringify(cart));
}

function loadPersistedCart(state) {
  let savedCart = localStorage.getItem("cart");
  if (savedCart) {
    state.cart = JSON.parse(savedCart);
  }
}

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

// function clearCart(state) {
//   state.cart = [];
//   persistCart(state.cart);
// }
