<template>
  <div class="container">
    <span class="titoli font-effect-shadow-multiple">
      <div class="titoli">
        <span class="emoji">&#x1F408;</span>
        {{ $t("info_title") }}
        <span class="emoji">&#x1F41B;</span>
      </div>
    </span>

    <div class="lead p-2" v-html="$t('baby_info1')"></div>
    <div class="lead p-2" v-html="$t('baby_info2')"></div>
    <div class="lead p-2" v-html="$t('baby_info3')"></div>

    <!-- <div class="mt-3">
      <img src="../assets/images/davide.jpg" class="img-fluid" alt="..." />
    </div> -->




    <div id="carouselExampleInterval" class="mt-3 carousel slide" data-bs-ride="carousel">
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 6"></button>
      </div>
      
      <div class="carousel-inner">
        
        <div class="carousel-item active" data-bs-interval="5000">
          <img src="../assets/images/info-1.jpg" class="d-block w-100" alt="...">
        </div>

        <div class="carousel-item" data-bs-interval="5000">
          <img src="../assets/images/info-4.png" class="d-block w-100" alt="...">
        </div>



        <div class="carousel-item" data-bs-interval="5000">
          <img src="../assets/images/info-2.jpg" class="d-block w-100" alt="...">
        </div>

        
        <div class="carousel-item" data-bs-interval="5000">
          <img src="../assets/images/info-3.jpg" class="d-block w-100" alt="...">
        </div>

        <div class="carousel-item" data-bs-interval="5000">
          <img src="../assets/images/info-5.jpg" class="d-block w-100" alt="...">
        </div>


        
        <div class="carousel-item" data-bs-interval="5000">
          <img src="../assets/images/info-10.jpg" class="d-block w-100" alt="...">
        </div>
        <!-- <div class="carousel-item">
          <img src="..." class="d-block w-100" alt="...">
        </div> -->
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>



    <div class="lead p-1 mt-3" v-html="$t('baby_info4')"></div>


  </div>
</template>

<script>
export default {
  name: "About",
  data() {
    return {};
  },
  methods: {},
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.container {
  max-width: 500px;
}

img {
  /* height: 400px; */
}
</style>
