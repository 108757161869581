const config = {
  port: 3003,
  giftEnabled: false,
  consoleOptions: {
    pattern: "HH:MM:ss.l",
    label: false,
  },
};

module.exports = config;
