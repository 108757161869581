<template>
  <div class="container-fluid">
    <div class="titoli">Reality</div>

    <p class="lead p-2">
      Come verranno usati davvero i vosti regali?
    </p>

  
    <div class="mt-3">
      <img src="../assets/images/esatto3.png" class="img-fluid" alt="..." />
    </div>

	<div class="progress-bg">
    	<div class="progress-bar">
        	<h3 class="raised">$50,000&nbsp;raised </h3>
        </div>
        	
        	<h3 class="goal">Goal: $100,000</h3>
    </div>

<!-- <div class="progress">
  <div class="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 75%"></div>
</div>
   -->

  <!-- <div class="row">
    <div class="col">1</div>
    <div class="col ">2</div>
  </div> -->


  </div>
</template>

<script>
export default {
  name: "Reality",
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped>
.container {
  max-width: 800px;
}

img {
  max-width: 500px;
}

body {
  padding: 50px;  
}

.progress-bg {
	margin: 0 auto;
	width: 65%;
	height: 78px;
	border-radius: 10px;
  text-align: center;
	-moz-box-shadow:    inset 0 0 10px #ccc;
	-webkit-box-shadow: inset 0 0 10px #ccc;
	box-shadow:         inset 0 0 10px #ccc;
}

.progress-bar {
	height: 78px;
	border-radius: 10px;
	float: left;
	width: 50%;
	/* fallback */ 
	background-color: #1c314a; 
	
	/* Safari 4-5, Chrome 1-9 */ 
	background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#1c314a), to(#27425f)); 
	
	/* Safari 5.1, Chrome 10+ */ 
	background: -webkit-linear-gradient(top, #1c314a, #27425f); 
	
	/* Firefox 3.6+ */ 
	background: -moz-linear-gradient(top, #1c314a, #27425f); 
	
	/* IE 10 */ 
	background: -ms-linear-gradient(top, #1c314a, #27425f); 
	
	/* Opera 11.10+ */ 
	background: -o-linear-gradient(top, #1c314a, #27425f);	
}

.progress-bg h3.goal, .progress-bg h3.raised {
	font-family: Arial,sans-serif;
  font-size: 2em;
  font-weight: 600;
	line-height: 78px;
	margin: 0;
	padding: 0;
	text-align: center;
	display: inline;
}


.progress-bg h3.raised {
	color: #fff;
	margin: 14px 25px 0 50px;
	padding: 0 25px 0 0;
}

.progress-bg h3.goal {
	color: #b2b2b2;
  text-align: center;
}

body .progress-bg h3.raised {
    -webkit-animation: fadein 4s; /* Safari and Chrome */
       -moz-animation: fadein 4s; /* Firefox */
        -ms-animation: fadein 4s; /* Internet Explorer */
         -o-animation: fadein 4s; /* Opera */
            animation: fadein 4s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari and Chrome */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.progress-bg h3.goal {
	float: right;
	display: inline;
	padding: 0 25px 0 0;
  text-align: center;
}

body .progress-bg div {
	-webkit-animation: progress-bar 2s ease forwards;
	-moz-animation: progress-bar 2s ease forwards;
	-o-animation: progress-bar 2s ease forwards;
	animation: progress-bar 2s ease forwards;
}

@-webkit-keyframes progress-bar {
    from { width: 0%; }
    to { width: 50%; }
}

@-moz-keyframes progress-bar {
    from { width: 0%; }
    to { width: 50%; }
}

@-o-keyframes progress-bar {
    from { width: 0%; }
    to { width: 50%; }
}

@keyframes progress-bar {
    from { width: 0%; }
    to { width: 50%; }
}



</style>
