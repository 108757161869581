<template>
  <div class="container messages">
    <div class="titoli">
      <span class="emoji">&#x1F418;</span>
      {{ $t("message_title") }}
      <span class="emoji"> &#x1F981;</span>
    </div>

    <p class="lead p-2">
      {{ $t("message_intro") }}
      <br />
    </p>

    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-lg-12 mb-3">
          <!-- <label for="text"  class="mb-2">Il tuo messaggio</label> -->
          <textarea
            id="text"
            class="form-control w-100"
            :placeholder="getWriteherePlaceholder"
            v-model="text"
            rows="10"
          ></textarea>

          <div class="mt-2"></div>
          <div class="row">
            <div class="col">
              <label for="author" class="mb-2">
                {{ $t("message_your_name") }}</label
              >
              <input
                placeholder="Autore"
                type="text"
                class="form-control w-100"
                v-model="author"
                id="author"
              />
            </div>
            <div class="col">
              <label for="location" class="mb-2">
                {{ $t("message_where_from") }}</label
              >
              <input
                placeholder=""
                type="text"
                class="form-control w-100"
                v-model="location"
                id="location"
              />
            </div>
          </div>

          <br />

          <!-- <button @click="submitMessage">Submit</button> -->

          <div class="btn btn-sm btn-login w-100 fs-4" @click="submitMessage">
            {{ $t("send_message") }}
          </div>
        </div>

        <div class="m-3"></div>

        <div class="col-xs-12 col-lg-12 titoli m-3" style="font-size: 1.5em">
          {{ $t("message_other_wrote") }}
        </div>

        <hr />

        <div
          v-for="msg in messages"
          :key="msg.id"
          class="border mb-3 shadow p-3 mb-5 bg-light rounded"
        >
          <div class="mt-2">
            <div class="fs-6">
              <span class="float-start">
                <em>{{ formatDateTime(msg.createdAt) }}</em>
              </span>
              <em class="float-end">{{ msg.author }} - {{ msg.location }}</em>
            </div>

            <div class="clearfix"></div>
            <hr />

            <p class="mt-2 fs-5" style="font-family: 'Sofia'">              
              <em style="white-space: pre-line;">{{ msg.text }}</em>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";

import { mapGetters } from "vuex";
import { messages } from "../../server/translations";
import moment from "moment";

export default {
  name: "message",
  data() {
    return {
      messages: [],
      text: "",
      author: "",
      location: "",
    };
  },
  getters: {},
  computed: {
    ...mapGetters(["isLogged"]),
    getWriteherePlaceholder() {
      let locale = this.$i18n.locale;
      return messages[locale].message_write_here;
    },
  },
  methods: {
    submitMessage() {
      let locale = this.$i18n.locale;

      //validate
      if (this.text == "" || this.author == "" || this.location == "") {
        let msg = messages[locale].message_missing_fields;

        this.$notify({
          group: "notif",
          type: "error",
          duration: 2000,
          title: "<div class='cayman'>🐊</div>",
          text: `<div>${msg}</div>`,
        });

        return;
      }

      this.axios
        .post("/api/message", {
          text: this.text,
          author: this.author,
          location: this.location,
        })
        .then(() => {
          // alert("Messaggio inviato correttamente, grazie!");

          // //visualizza subito msg
          // this.messages.unshift({
          //   text: this.text,
          //   author: this.author,
          //   location: this.location
          // })

          let msgOk = messages[locale].message_success;

          this.$notify({
            group: "notif",
            type: "success",
            duration: 4000,
            title: "<div class='cayman'>🐊</div>",
            text: `<div>${msgOk}</div>`,
          });

          this.text = "";
        });
    },
    loadMessages() {
      let url = "/api/message";
      this.axios.get(url).then((response) => {
        this.messages = response.data;
      });
    },
    formatDateTime(dt) {
      moment.locale(this.$i18n.locale);

      return moment(dt).fromNow();
    },
  },
  watch: {},
  mounted() {
    window.scrollTo(0, 0);

    let savedName = localStorage.getItem("name");
    if (savedName) {
      this.author = savedName;
    }

    this.loadMessages();

    //console.log(moment.now())
  },
};
</script>

<style>
.container.messages {
  max-width: 700px;
}

.message {
  /* text-align: center; */
}
</style>
