<template>
  <div class="container-fluid">
    <div class="login-form mx-auto p-4 m-4 border border-dark rounded">
      <div class="m-4 text-center">
        <!-- <font-awesome-icon
          class="p-3"
          style="font-size: 6em;"
          :icon="['far', 'exclamation-triangle']"
        /> -->
        <h1>Error</h1>
        <div v-if="error">
          <p>
            {{ error.status }} - {{ error.statusText }}
            <br />
            {{ error.data }}
          </p>
        </div>
        <hr />
        <router-link to="/">Return To Homepage</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {};
  },
  props: {
    error: Object,
  },
  created() {
    //console.log("created error.vue")
    if (!this.error) {
      console.log("missing error prop in error.vue");
    } else {
      console.log(this.error);
    }
  },
  mounted() {
    // console.log("mounted error.vue")
    // console.log(this.props.error)
  },
};
</script>
