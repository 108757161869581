<template>
  <div class="container">
    <!-- <h1>{{ $t("list_title") }}</h1> -->

    <span class="titoli font-effect-shadow-multiple">
      <div class="titoli">
        <span class="emoji">&#x1F414;</span>
        {{ $t("list_title") }}
        <span class="emoji">&#x1F422;</span>
      </div>
    </span>

    <div class="lead p-2" v-if="giftEnabled">
      {{ $t("list_intro1") }}

      <div v-if="this.$i18n.locale == 'en'">
        Once your choice is complete, you can send the payment by bank transfer
        or Satispay from the
        <router-link :to="{ name: 'Cart' }">{{ $t("cart_title") }}</router-link>
        page. If you prefer to make a free offer,
        <a href="#" @click.prevent="addFreeOffer">click here</a>
      </div>

      <div v-if="this.$i18n.locale == 'it'">
        Terminata la tua scelta potrai inviare il pagamento tramite bonifico
        bancario o Satispay dalla pagina
        <router-link :to="{ name: 'Cart' }">{{ $t("cart_title") }}</router-link
        >. Se invece preferisci fare un'offerta libera,
        <a href="#" @click.prevent="addFreeOffer">clicca qui</a>
      </div>

      <div v-if="this.$i18n.locale == 'sq'">
        Pas zgjedhjes suaj ju mund ta dërgoni pagesën me transfertë bankare ose
        Satispay nga faqja
        <router-link :to="{ name: 'Cart' }">{{ $t("cart_title") }}</router-link
        >. Nëse preferoni të bëni një ofertë të lirë,
        <a href="#" @click.prevent="addFreeOffer">klikoni këtu</a>
      </div>
    </div>
    <div v-else class="lead p-2">
      {{ $t("thanks_for_gifts") }}
    </div>

    <div
      class="d-flex justify-content-around row row-cols-xs-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 py-2"
    >
      <div
        v-for="item in items"
        :key="item.id"
        class="card my-3 pb-3"
        style="width: 18rem"
      >
        <div
          class="card-body"
          v-bind:class="{ 'added-cart': isItemInCart(item) === true }"
        >
          <!-- <div class="badge bg-primary ">EUR {{ item.price }}</div> -->

          <img
            v-bind:src="require('../assets/images/' + item.image)"
            alt="..."
            class="rounded mx-auto d-block img-thumbnail"
            v-bind:class="{ 'added-cart': isItemInCart(item) === true }"
          />

          <div class="fw-normal">{{ item.name }}</div>

          <div class="fs-5" v-if="isItemAvailable(item)">
            € {{ item.price }},00
          </div>
          <div class="fs-5" v-else></div>
        </div>
        <div class="card-footer">
          <div v-if="!isItemAvailable(item)">
            <em>Regalato da {{ item.donatedBy }}</em>
          </div>
          <div v-else>
            <div
              v-if="isItemInCart(item)"
              class="btn btn-warning w-100"
              @click="removeFromCart(item)"
            >
              Remove from Cart
            </div>
            <div v-else class="btn btn-login w-100" @click="addToCart(item)">
              Add to Cart
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="giftEnabled" class="row justify-content-center my-5">
      <div
        @click="goToCheckout"
        class="btn btn-warning mx-auto text-center w-50"
      >
        Vai al checkout
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "List",
  data() {
    return {
      mydata: "",
      items: [],
    };
  },
  computed: {
    ...mapGetters(["giftEnabled"]),
  },
  methods: {
    ...mapGetters(["getCart"]),
    addToCart: function (item) {
      this.$store.dispatch("addToCart", { item });
    },
    removeFromCart: function (item) {
      this.$store.dispatch("removeFromCart", { item });
    },
    getImgSource: function (item) {
      //:src="'/files/id_thumbs/' + file.id + '_001.jpg'"
      return `../assets/${item.image}`;
    },
    isItemAvailable: function (item) {
      return item.available;
    },
    isItemInCart: function (item) {
      let cartItems = this.getCart();

      //oggetto trovato e ritornato?
      return _.find(cartItems, item) != undefined;
    },
    addFreeOffer: function () {
      let idx = _.findIndex(this.items, { id: -1 });
      let item = this.items[idx];
      this.addToCart(item);

      this.$router.push({ name: "Cart" });
    },
    goToCheckout: function () {
      this.$router.push({ name: "Cart" });
    },
    loadPage: function () {
      let url = `/api/items`;

      this.axios.get(url).then((response) => {
        let gifts = response.data;

        //se regali disabilitati, vedo solo quelli fatti
        if (this.giftEnabled == false) {
          gifts = _.filter(gifts, {
            available: false,
          });
        }

        this.items = gifts;
      });
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.loadPage();
  },
};
</script>

<style scoped>
.card {
  /* background-color: lightgray; */
  /* padding: 20px; */
  /* padding-bottom: 1em; */

  border: 10px #e1a9a970 solid;
  border-radius: 10px;
}

.card-footer {
  background-color: transparent;
}

img {
  width: 200px;
  height: 200px;
  /* overflow: hidden; */
}

.added-cart {
  filter: grayscale(100%);
  opacity: 0.5;
}
</style>
