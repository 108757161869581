import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import List from "../views/List.vue";
import Message from "../views/Message.vue";
import Cart from "../views/Cart.vue";
import Info from "../views/Info.vue";
import Error from "../views/Error.vue";
import Reality from "../views/Reality.vue";
import Dashboard from "../views/Dashboard.vue";
import config from "./../../server/config.js";
import _ from "lodash";

Vue.use(VueRouter);

let routes = [
  // {
  //   path: "/",
  //   name: "Entry",
  //   component: Entry,
  //   props: true
  // },

  {
    path: "/",
    name: "Home",
    component: Home,
    props: true,
  },
  {
    path: "/list",
    name: "List",
    component: List,
  },
  {
    path: "/msg",
    name: "Message",
    component: Message,
  },
  {
    path: "/cart",
    name: "Cart",
    component: Cart,
    props: true,
  },
  {
    path: "/info",
    name: "Info",
    component: Info,
  },
  {
    path: "/ccc3b551-b5f1-4436-9622-0b0773fa87d9",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/reality",
    name: "Reality",
    component: Reality,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/error",
    name: "error",
    component: Error,
    props: true,
  },
];

//rimuovi il carrello se non ho regali abilitati
if (config.giftEnabled == false) {
  _.remove(routes, {
    name: "Cart",
  });
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
