import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { messages } from "./../server/translations";
//import { messages } from "./../server/translations";
// import i18n from '@/plugins/i18n';
import VueI18n from "vue-i18n";
// eslint-disable-next-line no-unused-vars
import axiosConfig from "./axiosConfig";
import axios from "axios";
import Notifications from "vue-notification";
import VueAxios from "vue-axios";

// import transition from "./../src/assets/scripts/transition.min"

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(Notifications);
Vue.use(VueI18n);
// Vue.use(transition);

const i18n = new VueI18n({
  locale: "it", // set locale
  messages: messages,
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
