/* eslint-disable */
module.exports = {
  messages: {
    it: {
      help1: "Proviamo una domanda più facile...",      
      welcome: "Benvenuto",
      whyhere: "Perché sei qui?",
      gift: "Voglio fare un regalo",
      info: "Voglio informazioni su Davide",
      msg: "Voglio lasciare un messaggio",
      another_question: "Non lo so, proviamo un'altra domanda",
      list_title: "Lista Regali",
      list_intro1: "Sosteniamo l'economia circolare! Abbiamo già comprato quasi tutti gli oggetti che ci serviranno, sia nuovi sia usati. Tantissimi vestiti ci sono stati regalati da amici e parenti con piccoli nati recentemente. Se però hai piacere di farci un regalino puoi contribuire scegliendolo dalla lista qui sotto.",
      cart_title: "Carrello",
      info_title: "Davide",      
      message_title: "Guestbook",
      message_intro: "Lasciaci un messaggio, verrà pubblicato nella bacheca qui sotto.",
      message_your_name: "Il tuo nome",
      message_where_from: "Da dove scrivi",
      message_send_message: "Invia messaggio",
      message_other_wrote: "Gli altri hanno scritto...",      
      message_write_here: "Scrivi qui...",
      your_name: "Come ti chiami? (Nome e Cognome)",
      login_error: "Sbagliato! Prova ancora...",
      missing_name: "Hai dimenticato di scrivere il tuo nome!",
      missing_answer: "Hai dimenticato di scrivere la risposta!",
      send_message: "Invia messaggio",
      message_success: "Grazie per il tuo messaggio! Verrà pubblicato al più presto",
      message_missing_fields: "Compila tutti i campi prima di inviare il messaggio",
      baby_info1: "Liza e Valerio sono lieti di annunciare l'arrivo del piccolo <strong>Davide</strong>!",
      baby_info2: "È nato a Milano all'Ospedale Buzzi il <strong>27 marzo 2022</strong> alle ore 19.57, al momento della nascita era lungo 51 cm e pesava 3,580 kg.",
      baby_info3: "Davide e la mamma stanno bene e torneranno presto a casa! Questa è la sua prima foto...",
      baby_info4: "Benvenuto piccolino!",
      cart_banktransfer: "Bonifico bancario",
      cart_otherpayment: "Altro",
      cart_emptycart: "Il carrello è vuoto!",
      cart_remove: "Rimuovi",
      cart_total: "Totale",
      cart_payment: "Pagamento",
      cart_previousorders: "Ordini precedenti",
      message_success_order: "Grazie per il tuo regalo da parte di Liza e Valerio!",
      cart_hidename: "Voglio nascondere il mio nome come donatore nella pagina regali. (Sarà comunque visibile a Liza e Valerio)",
      cart_other_confirm: "Confermo il mio ordine, invierò l'importo in altro modo",
      cart_order_ask_confirm: "Vuoi completare il tuo ordine?",
      cart_order_ask_confirm_satispay: "Grazie! Verrai reindirizzato verso Satispay.",
      cart_order_ask_confirm_paypal: "Grazie! Verrai reindirizzato verso Paypal.",
      cart_order_confirm_button: "Conferma il mio ordine",
      cart_goto_satispay: "Vai a Satispay",
      cart_goto_paypal: "Vai a Paypal",
      cart_send_total_msg_satispay: "Invia un totale di <strong>{totalCartEur}</strong> EUR tramite Satispay con il bottone qui sotto.",
      cart_send_total_msg_bank: "Invia un totale di <strong>{totalCartEur}</strong> EUR tramite bonifico bancario.",
      cart_beneficiary: "Beneficiario",
      thanks_for_gifts: "Grazie a tutti per i bellissimi regali!"
    },
    en: {      
      help1: "I don't know, let's try an easier question...",      
      welcome: "Welcome",
      whyhere: "Welcome! What would you like to do?",
      gift: "I would like to make a gift",      
      info: "I want information about Davide",
      msg: "I want to leave a message",
      another_question: "I don't know, let's try another question",
      list_title: "Gift List",
      list_intro1: "We support circular economy! We have already bought almost all the items we will need, both new and used. Lots of clothes were given to us by friends and relatives with babies. If you like to make us a gift you can contribute by choosing it from the list below.",
      cart_title: "My Cart",
      info_title: "Davide",
      message_title: "Guestbook",
      message_intro: "Leave us a message, it will be posted on the board below.",
      message_your_name: "Your name",
      message_where_from: "Your location",
      message_send_message: "Send message",
      message_other_wrote: "Others wrote...",
      message_write_here: "Write here...",
      your_name: "What's your name? (Name / Surname)",
      login_error: "Wrong! Please try again...",
      missing_name: "You forgot to write your name!",
      missing_answer: "You forgot to write the answer!",
      send_message: "Send message",
      message_success: "Thanks for the message! It will be published soon",
      message_missing_fields: "Fill in all the fields before sending the message",
      baby_info1: "Liza and Valerio are pleased to announce the arrival of little <strong> Davide </strong>!",
      baby_info2: "He was born in Milan at the Buzzi Hospital on the <strong> 27th March 2022 </strong> at 19.57, at the time of birth he was 51 cm long and weighed 3,580 kg.",
      baby_info3: "Davide and his mum are fine and will be back home soon! This is the first photo of him...",
      baby_info4: "Welcome little one!",
      cart_banktransfer: "Bank Transfer",
      cart_otherpayment: "Other",
      cart_emptycart: "The cart is empty!",
      cart_remove: "Remove",
      cart_total: "Total",
      cart_payment: "Payment",
      cart_previousorders: "Previous orders",
      message_success_order: "Thanks for your gift from Liza and Valerio!",
      cart_hidename: "I want to hide my name as a donor on the gifts page. (It will still be visible to Liza and Valerio)",
      cart_other_confirm: "I confirm my order, I will send the amount in another way",
      cart_order_ask_confirm: "Do you want to complete your order?",
      cart_order_ask_confirm_satispay: "Thank you! You will be redirected to Satispay.",
      cart_order_ask_confirm_paypal: "Thank you! You will be redirected to Paypal.",
      cart_order_confirm_button: "Confirm my order",
      cart_goto_satispay: "Go to Satispay",
      cart_goto_paypal: "Go to Paypal",
      cart_send_total_msg_satispay: "Send a total of <strong> {totalCartEur} </strong> EUR via Satispay using the button below.",
      cart_send_total_msg_bank: "Send a total of <strong> {totalCartEur} </strong> EUR by bank transfer.",
      cart_beneficiary: "Beneficiary",
      thanks_for_gifts: "Thank you all for your beautiful gifts!"
    },
    sq: {
      help1: "Nuk e di, le të provojmë një pyetje më të lehtë",      
      welcome: "Mirë se vini",
      whyhere: "Çfarë do të dëshironit të bënit?",
      gift: "Unë do të doja të bëj një dhuratë",
      info: "Dua informacion për Davide",
      msg: "Dua të lë një mesazh",
      another_question: "Nuk e di, le të provojmë një pyetje tjetër",
      list_title: "Lista e dhuratave",
      list_intro1: "Ne mbështesim ekonominë rrethore! Ne kemi blerë tashmë pothuajse të gjithë artikujt që do të na duhen, të rinj dhe të përdorur. Shumë rroba na i kanë dhënë miq dhe të afërm që kanë fëmijë të porsalindur. Por nëse ju pëlqen të na bëni një dhuratë, mund të kontribuoni duke e zgjedhur atë nga lista e mëposhtme.",
      cart_title: "Karroca e blerjeve",
      info_title: "Davide",      
      message_title: "Mesazhe",
      message_intro: "Na lini një mesazh, ai do të postohet në tabelën e buletinit më poshtë.",
      message_your_name: "Emri juaj",
      message_where_from: "Nga po shkruani",
      message_send_message: "Dërgoni mesazh",
      message_other_wrote: "Të tjerë shkruan...",
      message_write_here: "Shkruaj këtu...",
      your_name: "Si e ke emrin? (Emri dhe mbiemri)",
      login_error: "Gabim! Ju lutemi provoni sërish...",
      missing_name: "Keni harruar të shkruani emrin tuaj!",
      missing_answer: "Keni harruar të shkruani përgjigjen!",
      send_message: "Dërgoni mesazh",
      message_success: "Faleminderit për mesazhin! Do të publikohet së shpejti",
      message_missing_fields: "Plotësoni të gjitha fushat përpara se të dërgoni mesazhin",
      baby_info1: "Liza dhe Valerio njoftojnë me gëzim ardhjen e djalit të tyre të vogël, <strong>Davide.</strong>",
      baby_info2: "Ai lindi ne Milano në spitalin Buzzi me <strong>27 mars 2022</strong> në oren 19.57, në momentin e lindjes ishte 51cm i gjatë dhe peshonte 3.580 kg.",
      baby_info3: "Davide dhe mamaja e tij janë mirë dhe do të kthehen së shpejti në shtëpi! Kjo është fotografia e tij e parë...",
      baby_info4: "Mirë se ke ardhur vogëlush!",
      cart_banktransfer: "Bank Transfer",
      cart_otherpayment: "Tjetër",
      cart_emptycart: "Karroca është bosh",
      cart_remove: "Hiq",
      cart_total: "Total",
      cart_payment: "Pagesa",
      cart_previousorders: "Porositë e mëparshme",
      message_success_order: "Faleminderit për dhuratën tuaj nga Liza dhe Valerio!",
      cart_hidename: "Dua të fsheh emrin tim si donator në faqen e dhuratave. (Do të jetë ende e dukshme për Lizën dhe Valerio)",      
      cart_other_confirm: "Unë konfirmoj porosinë time, do ta dërgoj shumën në një mënyrë tjetër",
      cart_order_ask_confirm: "A doni të përfundoni porosinë tuaj?",
      cart_order_ask_confirm_satispay: "Faleminderit! Do të ridrejtoheni te Satispay.",
      cart_order_ask_confirm_paypal: "Faleminderit! Do të ridrejtoheni te Paypal.",
      cart_order_confirm_button: "Konfirmo porosinë time",
      cart_goto_satispay: "Shko te Satispay",
      cart_goto_paypal: "Shko te Paypal",
      cart_send_total_msg_satispay: "Dërgoni një total prej <strong> {totalCartEur} </strong> EUR nëpërmjet Satispay me butonin më poshtë.",
      cart_send_total_msg_bank: "Dërgoni një total prej <strong> {totalCartEur} </strong> EUR me transfertë bankare.",
      cart_beneficiary: "Përfituesi",
      thanks_for_gifts: "Faleminderit të gjithëve për dhuratat e bukura!"
    },
  },
};
