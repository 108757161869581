<template>
  <div id="app" class="">
    <div id="nav" class="user-select-none">
      <router-link :to="{ path: '/' }">Home</router-link>

      <span v-if="isLogged" class="">
        <router-link :to="{ name: 'Info' }">{{ $t("info_title") }}</router-link>
      </span>

      <span v-if="isLogged" class="">
        <router-link :to="{ name: 'Message' }">{{
          $t("message_title")
        }}</router-link>
      </span>

      <span v-if="isLogged">
        <!-- <span> | </span> -->
        <router-link :to="{ name: 'List' }">{{ $t("list_title") }}</router-link>
      </span>

      <span v-if="isLogged && giftEnabled" class="">
        <router-link :to="{ name: 'Cart' }"
          >{{ $t("cart_title") }} ({{ getCartCount }})</router-link
        >
      </span>

      <span class="nav-item" v-if="isLogged">
        <!-- bottone stilizzato come link -->
        <!-- <span> | </span> -->
        <a href="" class="" @click.prevent="logout">Logout</a>

        <!-- <button class="navlink mx-3 btn-link" @click="logout">Logout</button> -->
      </span>
    </div>

    <notifications
      class="m-4 warn"
      width="300px"
      position="top center"
      group="notif"
    />

    <router-view />

    <footer class="pt-3 m0-4">
      <ul class="nav justify-content-center border-top py-2 mb-0">
        <li class="">
          <span class="nav-link px-2 text-muted">© 2022 Valerio 🐊</span>
        </li>
        <!-- <li class="nav-item"><a href="#" class="nav-link px-2 text-muted">About</a></li> -->
        <li class="nav-item">
          <!-- <router-link :to="{ name: 'About' }" class="nav-link px-2 text-muted" >About</router-link>  -->
          <router-link
            v-if="isLogged"
            :to="{ name: 'About' }"
            class="nav-link px-2"
            >About</router-link
          >
        </li>
      </ul>
      <!-- <p class="text-center text-muted ">
      <span>© 2022 Valerio 🐊</span>
      <span>About</span>
    </p> -->
    </footer>
  </div>
</template>

<script>
//import TopBar from "@/components/TopBar.vue";
import { mapGetters } from "vuex";
const config = require("./../server/config.js");

export default {
  name: "app",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    //TopBar
  },
  // data() {
  //   return {
  //       languages: [
  //           { flag: 'us', language: 'en', title: 'English' },
  //           { flag: 'es', language: 'es', title: 'Español' }
  //       ]
  //   };
  // },
  computed: {
    ...mapGetters(["isLogged", "getCartCount", "giftEnabled"]),
  },
  // watch: {
  //   $route: "changeLocale"
  // },
  methods: {
    changeLocale(lang) {
      this.$i18n.locale = lang;

      localStorage.setItem("locale", lang);
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        //alert("ok bye bye");
        this.$router.push("/");
      });
    },
  },
  beforeMount() {
    console.log("beforeMount");

    var savedLang = localStorage.getItem("locale");
    if (savedLang) {
      this.$i18n.locale = savedLang;
    }

    this.$store.dispatch("verifyAuthStatus");
  },
};
</script>

<style>
#app {
  background-color: #e7f2f8;
  padding-bottom: 3em;
  /* height: 100%; */
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50; */
}

.titoli {
  font-family: "Sofia", sans-serif;
  font-size: 45px;
  text-shadow: 3px 3px 3px #ababab;
  text-align: center;
}

.titoli-small {
  font-family: "Sofia", sans-serif;
  font-size: 35px;
  text-shadow: 2px 2px 2px #ababab;
  text-align: center;
}

#nav {
  padding: 15px;
  text-align: center;
  /* color: #2c3e50; */

  background-color: #1d3557;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#nav a {
  font-weight: bold;
  /* color: #2c3e50; */
  color: wheat;
  padding-right: 1em;
  text-decoration: none;
  opacity: 0.7;
  white-space: nowrap;
}

#nav a.router-link-exact-active {
  /* color: #42b983; */
  font-size: 1.2em;
  text-decoration: underline;
  opacity: 1;
}

.nav-item:hover {
  text-decoration: underline;
}

.notification-title {
  text-align: center;
}
.notification-content {
  font-size: 1.3em;
  text-align: center;
}

.vue-notification-template.vue-notification.warn {
  color: black;
  background-color: #ffa384;
}
.vue-notification-template.vue-notification.error {
  color: white;
}

.cayman {
  zoom: 400%;
}

.emoji {
  zoom: 60%;
}
</style>
