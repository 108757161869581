import axios from "axios";
import router from "@/router/index.js";
import store from "@/store/index.js"; // path to your Vuex store

axios.defaults.baseURL = "";
axios.defaults.headers = {
  Accept: "application/json",
  "Content-Type": "Application/json",
};

const loginUrl = "auth/login";

const isHandlerEnabled = (config = {}) => {
  return Object.prototype.hasOwnProperty.call(config, "handlerEnabled") &&
    !config.handlerEnabled
    ? false
    : true;
};

axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    let token = localStorage.getItem("jwt");
    if (token) {
      let h = config.headers;
      h["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

const errorHandler = (error) => {
  if (isHandlerEnabled(error.config)) {
    if (error.response.status == 401) {
      console.log("logout action called from axios error 401 handler");
      store.dispatch("logout");

      if (error.response.config.url != loginUrl) {
        //se siamo sulla pagina del login, non fare redirect
        router.push({ name: "error", params: { error: error.response } });
      }
    } else {
      //errore generico
      router.push({ name: "error", params: { error: error.response } });
    }
  }
  //debugger
  //return Promise.reject( { ...error })  //ritorna tutto l'oggetto config error
  return Promise.reject(error.response);
  //return Promise.resolve();
};

const successHandler = (response) => {
  if (isHandlerEnabled(response.config)) {
    // Handle responses
  }

  // //se il server chiede un jwt reissue, lo salvo
  // if (response.headers["jwt-reissue"]) {
  //   var newToken = response.headers["jwt-reissue"];
  //   store.commit("SET_LOGIN", {
  //     token: newToken
  //   });
  // }
  return response;
};

axios.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);

// const appService = {
//   addAuthHeader(token) {
//     console.log("addAuthHeader called");

//     axios.defaults.headers.Authorization = `Bearer ${token}`;

//     // var h = axios.defaults.headers;
//     // h.Authorization = `Bearer ${token}`;
//   },

//   removeAuthHeader() {
//     console.log("removeAuthHeader called");
//     delete axios.defaults.headers.Authorization;
//     //axios.defaults.headers.Authorization = "";
//   }
// };

export default axios;
