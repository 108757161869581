<template>
  <div>
    <div v-if="isLogged">
      <h5>Dashboard</h5>

      <table class="table">
        <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Author</th>
            <th scope="col">Text</th>
            <th scope="col">Approved</th>
            <th scope="col">Posted</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="msg in messages" :key="msg.id">
            <th scope="row">{{ msg.id }}</th>
            <td>{{ msg.author }}</td>
            <td>{{ msg.text }}</td>
            <td>
              <input
                type="checkbox"
                v-model="msg.approved"
                @change="invertApproval(msg)"
                class="cb"
              />
              <!-- {{msg.approved}} -->
            </td>
            <td>{{ formatDateTime(msg.createdAt) }}</td>
          </tr>
        </tbody>
      </table>

      <!-- <div v-for="msg in messages" :key="msg.id" >
      <div>
        <div>{{msg.id}}</div>
        <div>{{msg.message}}</div>
        <div>{{msg.approved}}</div>
      </div>
    </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "dashboard",
  data() {
    return {
      messages: [],
    };
  },
  computed: {
    ...mapGetters(["isLogged"]),
  },
  methods: {
    formatDateTime(dt) {
      moment.locale(this.$i18n.locale);

      return moment(dt).fromNow();
    },
    invertApproval(msg) {
      let url = `/api/message/invert/${msg.id}`;

      console.log(url);

      this.axios.patch(url).then(() => {
        //this.messages = response.data;
        //this.loadMessages()
      });
    },
    loadMessages() {
      let url = "/api/message/all";
      this.axios.get(url).then((response) => {
        this.messages = response.data;
      });
    },
  },
  mounted() {
    this.loadMessages();
  },
};
</script>

<style scoped>
.cb {
  zoom: 1.5;
}
</style>
