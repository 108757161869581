<template>
  <div class="container">
    <!-- <div class="titoli" >{{ $t("cart_title") }}</div> -->

    <span class="titoli font-effect-shadow-multiple">
      <div class="">
        <span class="emoji">&#x1F42A;</span>
        {{ $t("cart_title") }}
        <span class="emoji">&#x1F438;</span>
      </div>
    </span>

    <div v-if="items.length == 0">
      <p class="text-center m-3">
        <em>
          {{ $t("cart_emptycart") }}
        </em>
      </p>
    </div>

    <div v-if="items.length > 0">
      <!-- visibile su xs e md -->
      <div class="d-sm-block d-xs-block d-md-none">
        <div v-for="item in items" :key="item.id">
          <div>
            <img
              v-bind:src="require('../assets/images/' + item.image)"
              class="rounded mx-auto d-block"
              alt="..."
              width="30px"
            />
          </div>
          <div v-if="item.id > -1" class="text-center">
            {{ item.name }}
            <span> € {{ item.price }} </span>
          </div>

          <div v-else>
            <div>
              <div class="text-center">{{ item.name }} €</div>
              <input
                type="number"
                min="1"
                max="1000"
                class="form-control w-75 mx-auto"
                style="max-width: 200px"
                :value="item.price"
                @change="updateItemPrice($event, item)"
              />
            </div>
          </div>

          <div class="text-center mt-2">
            <a href="" class="fs-6" @click="removeFromCart(item)">{{
              $t("cart_remove")
            }}</a>
          </div>
          <hr />
        </div>
      </div>

      <!-- nascondi su schermi piccoli xs e md -->
      <div class="d-none d-md-block">
        <table class="table align-middle">
          <colgroup>
            <col span="1" style="width: 15%" />
            <col span="1" style="width: 55%" />
            <col span="1" style="width: 20%" />
            <col span="1" style="width: 10%" />
          </colgroup>
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Item</th>
              <th scope="col">€</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td>
                <img
                  v-bind:src="require('../assets/images/' + item.image)"
                  class="rounded mx-auto d-block"
                  alt="..."
                  width="30px"
                />
              </td>
              <td>{{ item.name }}</td>
              <td>
                <span v-if="item.id == -1">
                  <input
                    type="number"
                    min="1"
                    max="1000"
                    class="form-control w-75"
                    :value="item.price"
                    @change="updateItemPrice($event, item)"
                  />
                </span>
                <span v-else> € {{ item.price }} </span>
              </td>
              <td>
                <a
                  href=""
                  class="fs-3"
                  style="text-decoration: none; color: brown"
                  @click.prevent="removeFromCart(item)"
                  >🞭</a
                >
              </td>
            </tr>

            <tr>
              <td></td>
              <td class="text-end">{{ $t("cart_total") }}</td>
              <td>€ {{ totalCartEur }}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="totalCartEur > 0">
      <div class="m-2">
        <strong class="">{{ $t("cart_payment") }}</strong>
      </div>
      <!-- <div class="form-check mt-2">
      <input class="form-check-input" type="radio" name="radioPayment" value="paypal" id="radioPaypal" v-model="selectedRadio" checked>
      <label class="form-check-label" for="radioPaypal">
        Paypal
      </label>
    </div> -->
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="radioPayment"
          value="transfer"
          id="radioTransfer"
          v-model="selectedRadio"
        />
        <label class="form-check-label" for="radioTransfer">
          {{ $t("cart_banktransfer") }}
        </label>
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="radioPayment"
          value="satispay"
          id="radioSatispay"
          v-model="selectedRadio"
        />
        <label class="form-check-label" for="radioSatispay">
          Satispay / Paypal
        </label>
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="radioPayment"
          value="other"
          id="radioOther"
          v-model="selectedRadio"
        />
        <label class="form-check-label" for="radioOther">
          {{ $t("cart_otherpayment") }}
        </label>
      </div>

      <div class="mt-4">
        <div v-if="selectedRadio == 'transfer'">
          <div v-html="$t('cart_send_total_msg_bank', { totalCartEur })"></div>

          <div class="my-3">
            <table class="table">
              <tbody>
                <tr>
                  <td scope="row">{{ $t("cart_beneficiary") }}:</td>
                  <td>SOPPI LIZABETA</td>
                </tr>
                <tr>
                  <td scope="row">IBAN:</td>
                  <td>IT68J0301503200000003678516</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="form-check my-3">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
              v-model="anonymousDonation"
            />
            <label class="form-check-label" for="flexCheckDefault">
              {{ $t("cart_hidename") }}
            </label>
          </div>

          <div
            class="btn btn-sm btn-warning w-100 my-3"
            @click="confirmOrder('transfer')"
          >
            {{ $t("cart_order_confirm_button") }}
          </div>
        </div>

        <div v-if="selectedRadio == 'satispay'">
          <!-- <p>Invia un totale di <strong>{{ totalCartEur }}</strong> EUR tramite SatisPay con il link qui sotto </p> -->

          <div
            v-html="$t('cart_send_total_msg_satispay', { totalCartEur })"
          ></div>

          <div class="form-check my-3">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
              v-model="anonymousDonation"
            />
            <label class="form-check-label" for="flexCheckDefault">
              {{ $t("cart_hidename") }}
            </label>
          </div>

          <div
            class="btn btn-sm btn-satispay"
            @click="confirmOrder('satispay')"
          >
            {{ $t("cart_goto_satispay") }}
          </div>

          <span class="mx-3"></span>
          <div class="btn btn-sm btn-paypal" @click="confirmOrder('paypal')">
            {{ $t("cart_goto_paypal") }}
          </div>
        </div>

        <div v-if="selectedRadio == 'other'">
          <div class="form-check my-3">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
              v-model="anonymousDonation"
            />
            <label class="form-check-label" for="flexCheckDefault">
              {{ $t("cart_hidename") }}
            </label>
          </div>

          <div
            class="btn btn-sm btn-warning w-100"
            @click="confirmOrder('other')"
          >
            {{ $t("cart_other_confirm") }}
          </div>
        </div>
      </div>
    </div>

    <!-- ordini precedenti -->
    <div class="mt-5" v-if="orders && orders.length > 0">
      <hr />
      <h5>{{ $t("cart_previousorders") }}</h5>

      <div>
        <div>
          <ul>
            <li class="mt-2" v-for="order in orders" :key="order.id">
              {{ formatDate(order.createdAt) }}

              <ul>
                <li v-for="item in order.cart" :key="item.id">
                  {{ item.name }} (€ {{ item.price }})
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import { _ } from "lodash";
import moment from "moment";
import { messages } from "../../server/translations";

export default {
  name: "Cart",
  data() {
    return {
      items: [],
      orders: [],
      selectedRadio: null,
      anonymousDonation: false,
      freeOffer: false,
    };
  },
  watch: {
    "$store.state.cart": function () {
      //ricarichiamo se cambia la cart
      this.loadPage();
    },
  },
  computed: {
    totalCartEur: function () {
      let cart = this.getCart();
      let sum = 0;
      if (cart) {
        for (let index = 0; index < cart.length; index++) {
          sum += cart[index]["price"];
        }
      }
      return sum;
    },
    paypal_desc: function () {
      //descrizione pagamento paypal
      return ``;
    },
    satispay_link: function () {
      //v
      //https://www.satispay.com/app/match/link/user/S6Y-CON--E98BF811-8B1B-486F-B58F-F8319720F7CC?amount=547&currency=EUR

      //liz
      return `https://www.satispay.com/app/match/link/user/S6Y-CON--E98BF811-8B1B-486F-B58F-F8319720F7CC?amount=${this.totalCartEur}00&currency=EUR`;
    },
    paypal_link: function () {
      //liz
      return `https://www.paypal.com/paypalme/val41996/EUR${this.totalCartEur}`;
    },
  },
  methods: {
    ...mapGetters(["getCart"]),
    ...mapActions(["clearCart"]),
    updateCartItem: function (item) {
      this.$store.dispatch("updateCartItem", { item });
    },
    removeFromCart: function (item) {
      this.$store.dispatch("removeFromCart", { item });
      this.loadPage();
    },
    updateItemPrice(e, item) {
      let new_price = parseInt(e.target.value);
      item.price = new_price;
      this.updateCartItem(item);
    },
    loadPage: function () {
      this.items = [];
      this.items = this.getCart();

      this.orders = this.getPreviousOrders();
    },
    getPreviousOrders: function () {
      let uuid = localStorage.getItem("uuid");

      if (uuid) {
        let url = `/api/cart/get_orders/${uuid}`;
        this.axios.get(url).then((result) => {
          this.orders = result.data;
        });
      }
    },
    formatDate: function (date) {
      moment.locale(this.$i18n.locale);
      return moment(date).format("LLL");
    },
    getOrderItems: function (order) {
      let orderItems = JSON.parse(order.cart);
      console.table(orderItems);
    },
    confirmOrder(paymentMethod) {
      let locale = this.$i18n.locale;

      // let msg = messages[locale].message_missing_fields;

      let isConfirmed = false;
      if (paymentMethod == "satispay") {
        isConfirmed = confirm(messages[locale].cart_order_ask_confirm);
      }

      if (paymentMethod == "paypal") {
        isConfirmed = confirm(messages[locale].cart_order_ask_confirm);
      }

      if (paymentMethod == "transfer") {
        isConfirmed = confirm(messages[locale].cart_order_ask_confirm);
      }

      if (paymentMethod == "other") {
        isConfirmed = confirm(messages[locale].cart_order_ask_confirm);
      }

      if (isConfirmed) {
        //salva ordine in tabella

        //        this.axios.get("https://api.telegram.org/bot5183985765:AAH9ZZ5HUJ_Z6s0pBmFkGhRKl_tD1Q5qDiI/sendMessage?chat_id=-1001628446692&text=ottime notizie ecc")

        let body = {
          cart: this.items,
          total: this.totalCartEur,
          name: localStorage.getItem("name"),
          uuid: localStorage.getItem("uuid"),
          paymentMethod,
          anonymousDonation: this.anonymousDonation,
        };

        this.axios.post("/api/cart/confirm_order", body).then(() => {
          this.$store.dispatch("clearCart");
          this.loadPage();
          //TODO vedere conferma ordine e cancellare cart

          //alert("grazie")

          //this.$store.dispatch("clearCart")

          if (paymentMethod == "transfer" || paymentMethod == "other") {
            let locale = this.$i18n.locale;
            let msgOk = messages[locale].message_success_order;

            this.$notify({
              group: "notif",
              type: "info",
              duration: 6000,
              title: "<div class='cayman'>❤️</div>",
              text: `<div>${msgOk}</div>`,
            });
          }
        });

        //se satispay, apri link
        if (paymentMethod == "satispay") {
          alert(messages[locale].cart_order_ask_confirm_satispay);
          window.location.href = this.satispay_link;
        }

        if (paymentMethod == "paypal") {
          alert(messages[locale].cart_order_ask_confirm_paypal);
          window.location.href = this.paypal_link;
        }

        //ringrazia
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);

    this.loadPage();
  },
};
</script>

<style scoped>
.container {
  max-width: 700px;
}

table {
  font-size: 1.2em;
}

.btn-satispay {
  background-color: rgb(249, 76, 67);
  border-color: rgb(226, 46, 36);
  color: white;
  font-size: 1em;
}

.btn-satispay:hover {
  background-color: rgb(165, 31, 24);
  border-color: rgb(128, 17, 11);
  color: white;
}

.btn-paypal {
  background-color: #344cad;
  border-color: #2841a3;
  color: white;
  font-size: 1em;
}

.btn-paypal:hover {
  background-color: #142c8e;
  border-color: #0c2279;
  color: white;
}

/* .card {
    background-color: lightgray;
    padding: 20px;
  }

  .card-footer {
    background-color: transparent;
  } */

img {
  width: 80px;
  height: 80px;
}
</style>
